import { useState } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

import CenteredFlexDiv from 'assets/styles/DivCenteredFlex'

const CgusContent: any = () => {
  const pdfURL = './CGUs-PHARMACIE-XXX.pdf'

  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  const onLoadErrorLog: any = (err: any) => {
    console.log(err)
  }

  const onDocumentLoadSuccess: any = ({ numPages }: { numPages: any}) => {
    setNumPages(numPages)
  }

  return (
    <CenteredFlexDiv>
      <Document file={pdfURL} onLoadError={onLoadErrorLog} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </CenteredFlexDiv>
  )
}

export default CgusContent
