import { Container, Row, Col } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { RootState } from 'models/redux.model'
import { setSelectedPharma } from 'actions'

import SideMenu from '../components/SideMenu'
import Breadcrum from '../components/ui/Breadcrum'
import CgusContent from '../components/Cgus/CgusContent'

import { ContainerWrapper } from 'assets/styles/ContainerWrapper'
import { OrdersWrapper } from 'assets/styles/OrdersWrapper'

import SelectPharma from 'components/SelectPharma'
import { useIntl } from 'react-intl'

import { alertError, alertSuccess } from '../utils/notifications'

import API from 'utils/API'

const Cgus = (): any => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl()
  const f = (id: any): string => formatMessage({ id })
  const pharmacies = useSelector((state: RootState) => state.user.pharmacies)
  const selectedPharma = useSelector((state: RootState) => state.user.selectedPharma)
  const isCgusAccepted = selectedPharma?.pharmacy?.cgus_accepted || false
  const history = useHistory();

  const handleOnSubmit = async () => {
    if (selectedPharma != null) {
      alertSuccess(f("valid"))
      try {
        await API.post(`/pharmacist/cgus-validation`, 
          {
            "pharmacyId": selectedPharma.pharmacy_id,
            "cguBoolean": true
          }
        )
        dispatch(setSelectedPharma({ ...selectedPharma, pharmacy: { ...selectedPharma.pharmacy, cgus_accepted: true }}));
        history.push('/orders');
      } catch (error) {
        alertError(f('error'))
    }
  } else {
      alertError(f('noPharma'))
    }
  }


  return (
    <OrdersWrapper property='qrcode' className='dataContainer' style={{ height: '100%' }}>
      <SideMenu parent='cgus' />
      <ContainerWrapper>
        <Container fluid>
          <Row className='headerTitle'>
            <Col>
              <h2>CONDITIONS GENERALES D’UTILISATION PHARMACIE</h2>
              <Breadcrum />
            </Col>
          </Row>
          <Row style={{ justifyContent: 'space-between' }}>
            <SelectPharma pharmacies={pharmacies} />
          </Row>
          <Row>
            <CgusContent />
          </Row>
          <Row className="flex justify-center">
              <button 
               onClick={handleOnSubmit}
               disabled={isCgusAccepted}
               className='btn btn-primary mx-auto my-0'>
                {isCgusAccepted ? f('CGUsAccepted') : f('ValidateAndContinue')}
              </button>
          </Row>
        </Container>

      </ContainerWrapper>
    </OrdersWrapper>
  )
}

export default Cgus
